<template>
  <b-overlay :show="isLoading" rounded="lg">
  <b-card title="Retrigger API DO List">
    <b-col cols="12">
      <b-row class="hide-on-desktop" v-if="btnshow">
        <b-col cols="12">
          <b-row>
            <b-button
              size="lg"
              class="w-100 d-flex justify-content-center align-items-center"
              style="margin-bottom:15px;"
              variant="primary"
              @click="btnForMobile('All')"
            >
              All DO
            </b-button>
          </b-row>
          <b-row>
            <b-button
              size="lg"
              class="w-100 d-flex justify-content-center align-items-center"
              style="margin-bottom:15px;"
              variant="primary"
              @click="btnForMobile('Update DO Status')"
            >
              Update DO Status
            </b-button>
          </b-row>
          <b-row>
            <b-button
              size="lg"
              class="w-100 d-flex justify-content-center align-items-center"
              style="margin-bottom:15px;"
              variant="primary"
              @click="btnForMobile('Return DO')"
            >
              Return DO
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <b-row :class="btnBackMobile">
        <b-button size="md" @click="actBackMobile" variant="primary">
          Back
        </b-button>
      </b-row>
      <b-row>
        <b-col md="4" class="ml-md-auto hide-on-mobile">
          <strong>API Type :</strong>
          <b-form-select
            @input="(value) => statusFiltering(value)"
            id="delivery-status"
            v-model="deliveryStatus"
            :options="listStatus"
            @click="filterDelivery"
          >
          </b-form-select>
        </b-col>
        <b-col md="4" :class="conditionView">
          <strong>Search by Status :</strong>
          <b-form-select
            @input="(value) => searchStatus(value)"
            id="resend-API-status"
            v-model="resendStatus"
            :options="resendStatusList"
            @click="filterDelivery"
          >
          </b-form-select>
        </b-col>
        <b-col md="4" :class="conditionView">
          <b-form-input
            
            v-model="search"
            type="search"
            placeholder="Search by DO"
            style="float: right; margin-top: 20px; margin-bottom: 30px;"
          />
        </b-col>
        <b-col :class="conditionView" class="mb-1">
          <b-button @click="doFiltering" variant="primary" style="margin-right: 10px;">search</b-button>
          <span style="color: red;"></span>
        </b-col>
      </b-row>
      <b-row :class="conditionView">
        <b-table
          class="custom-row-height"
          responsive
          small
          hover
          bordered
          show-empty
          :items="filterDelivery"
          :fields="fields"
          :busy.sync="tableLoading"
          :stacked="stackedStatus"
        >
          <template #cell(index)="row">
            {{ row.index + 1 + (currentPage - 1) * 10 }}
          </template>

          <template #cell(filter)="row">
            {{ displayFilter(row.item) }}
          </template>

          <template #cell(status)="row">
            {{ displayStatus(row.item) }}
          </template>

          <template v-slot:cell(deliveryDate)="row">
            {{ dates(row.item.deliveryDate) }}
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              id="dropdown-dropleft"
              :dropleft="!mobileView"
              variant="primary"
              size="sm"
              text="Menu"
              no-caret
            >
              <template #button-content>
                <feather-icon size="1x" icon="MenuIcon" />
              </template>
              <b-dropdown-item style="text-align:center">
                <b-button
                  size="sm"
                  style="text-align:center"
                  variant="info"
                  @click="toDetailPage(row.item)"
                  class="mr-1"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  permission.resend &&
                    (row.item.resendAPI === 'Resend Return' ||
                    row.item.resendAPI === 'Resend Status' ||
                    row.item.resendAPI === 'Redelivery Status')
                "
              >
                <b-button
                  size="sm"
                  variant="primary"
                  :disabled="isLoading"
                  @click="toResend(row.item)"
                  class="mr-1"
                >
                  Resend to SAP
                </b-button>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-row>
      <b-row :class="conditionView" style="margin-top:10px">
        <b-col md="9" />
        <b-col md="3">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            align="fill"
            class="hide-on-desktop"
          />
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            class="hide-on-mobile"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { userAccess, dateFormat } from "@/utils/utils";
import lodash from "lodash";
export default {
  data() {
    let mobileViewQuery = window.matchMedia("(max-width: 761px)"); //check true or false width screen mobile view
    return {
      mobileViewQuery: mobileViewQuery, //all info matchMedia
      mobileView: mobileViewQuery.matches, // return true or false match screen moobile view
      //FIELD FOR MOBILE
      btnshow: true,
      showDropdown: true,
      btnBackMobile: "hide-at-all",
      conditionView: "ml-md-auto hide-on-mobile",
      stackedStatus: "md",
      //END FIELD FOR MOBILE
      fields: [
        { key: "index", label: "No" },
        { key: "sapSo", label: "SAP SO", filterable: true, sortable: true },
        { key: "sapDo", label: "SAP DO", filterable: true, sortable: true },
        {
          key: "deliveryDate",
          label: "Delivery Date",
          filterable: true,
          sortable: true,
        },
        { key: "filter", label: "FILTER", filterable: true, sortable: true },
        { key: "status", label: "STATUS", filterable: true, sortable: true },
        { key: "actions", label: "Actions" },
      ],
      perPage: 10,
      currentPage: 1,
      search: "",
      totalRows: 1,
      tableLoading: true,
      selectData: "",
      listStatus: ["All", "Update DO Status", "Return DO"],
      resendStatusList : ['All', 'Completed', 'Resend'],
      defaultStatus:"All",
      deliveryStatus: "",
      resendStatus: "All",
      customerCollect: {
        idNumber: "",
        name: "",
      },
      delivDate: "",
      //for disable button
      isLoading : false,
    };
  },
  computed: {
    filterDelivery() {
      var result = this.deliveryOrders;

      if (this.deliveryStatus == "Update DO Status") {
        result = result.filter(
          (x) =>
            x.resendAPI === "Resend Status" ||
            x.resendAPI === "Redelivery Status" ||
            x.resendAPI === "Status Completed"
        );
      } else if (this.deliveryStatus == "Return DO") {
        result = result.filter(
          (x) =>
            x.resendAPI === "Resend Return" ||
            x.resendAPI === "Return Completed"
        );
      } else {
        result = result;
      }
      console.log("result", result);
      return result;
    },
    deliveryOrders() {
      var tempDO = this.$store.getters["delivery/getDO"].filter((x) => {
        return x.resendAPI !== undefined;
      });
      console.log("tempDo", tempDO);
      return tempDO;
    },
    permission() {
      let result = userAccess("Resend to SAP", "retrigger_api_menu");
      // console.log("Resend to SAP",result)
      return result;
    },
  },
  mounted() {
    // this.$store.dispatch("delivery/getDeliveryOrder");

    //temporary for detail dropdown button conditional by mobile view or desktop view
    this.mobileViewQuery.addListener(() => {
      this.mobileView = this.mobileViewQuery.matches;
    });

    var isMobile = window.matchMedia("(max-width: 761px)").matches;
    console.log("mobile is ", isMobile);
    var queryAvailable = -1;
    var query;
    if (this.$route.query.status) {
      query = this.$route.query.status;
      queryAvailable = this.listStatus.findIndex((x) => {
        return x == query;
      });
    }
    console.log("queryavailable ", queryAvailable);
    if (queryAvailable >= 0) {
      if (isMobile) {
        this.btnForMobile(query);
        return;
      }
      this.deliveryStatus = query;
    } else {
      this.deliveryStatus = this.defaultStatus;
    }
    if (this.$route.query.sapDo) {
      this.search = this.$route.query.sapDo;
      this.doFiltering()
    }
  },
  created() {
    document.title = "Retrigger API | RSP";
    console.log("this.deliveryStatus", this.deliveryStatus);
    let wantedStatus = [
      "Resend Status",
      "Redelivery Status",
      "Status Completed",
      "Resend Return",
      "Return Completed",
    ];

    
  },
  methods: {
    ...mapActions({
      testResendToSAP: "delivery/resendToSAP",
      getDeliveryOrder: "delivery/getDeliveryOrder",
    }),
    //METHOD FOR MOBILE VIEW
    dates(date) {
      return dateFormat(date);
    },
    onPageChange(page) {
      this.currentPage = page;
      this.queryDelivery();
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      console.log("this.totalRows", this.totalRows);
      this.tableLoading = false;
    },
    queryDelivery() {
      let wantedStatus = [
        "Resend Status",
        "Redelivery Status",
        "Status Completed",
        "Resend Return",
        "Return Completed",
      ];
      let result = [];
      if (this.deliveryStatus == "Update DO Status") {
        result = ["Resend Status","Redelivery Status", "Status Completed"];
      } else if (this.deliveryStatus == "Return DO") {
        result = ["Resend Return", "Return Completed"];
      } else if (this.deliveryStatus == "All") {
        result = wantedStatus;
      } else {
        result = result;
      }

      let resultStatus = [];
      if (this.resendStatus == "Completed"){
        resultStatus = ["Status Completed", "Return Completed"]
      }  else if (this.resendStatus == "Resend") {
        resultStatus = ["Resend Return", "Resend Status", "Redelivery Status"];
      } else if (this.resendStatus == "All") {
        resultStatus = wantedStatus;
      } 

      const intersection = result.filter((value) =>
        resultStatus.includes(value)
      );
      
      this.getDeliveryOrder({
        page: this.currentPage,
        entry: this.perPage,
        sapDo: this.search,
        resendAPI: intersection,
        delivDate: this.delivDate,
      }).then((data) => {
        console.log("data", data);
        this.processMetadata(data.metadata);
      });
    },
    // doFiltering: lodash.debounce(function(value) {
    //   this.search = value;
    //   this.currentPage = 1;
    //   this.queryDelivery();
    // }, 500),
    doFiltering(){
      this.isLoading= true
      let wantedStatus = [
        "Resend Status",
        "Redelivery Status",
        "Status Completed",
        "Resend Return",
        "Return Completed",
      ];
      let result = [];
      if (this.deliveryStatus == "Update DO Status") {
        result = ["Resend Status","Redelivery Status", "Status Completed"];
      } else if (this.deliveryStatus == "Return DO") {
        result = ["Resend Return", "Return Completed"];
      } else if (this.deliveryStatus == "All") {
        result = wantedStatus;
      } else {
        result = result;
      }

      let resultStatus = [];
      if (this.resendStatus == "Completed"){
        resultStatus = ["Status Completed", "Return Completed"]
      }  else if (this.resendStatus == "Resend") {
        resultStatus = ["Resend Return", "Resend Status", "Redelivery Status"];
      } else if (this.resendStatus == "All") {
        resultStatus = wantedStatus;
      } 

      const intersection = result.filter((value) =>
        resultStatus.includes(value)
      );
      console.log('intersection', intersection);
      this.getDeliveryOrder({
        page: this.currentPage,
        entry: this.perPage,
        sapDo: this.search,
        resendAPI: intersection,
        delivDate: this.delivDate,
      }).then((data) => {
        this.isLoading = false
        this.processMetadata(data.metadata);
      }).catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      });
    },
    statusFiltering(value) {
      this.deliveryStatus = value;
      // this.currentPage = 1;
      // this.queryDelivery();
    },
    searchStatus(value) {
      this.resendStatus = value;
      // this.currentPage = 1;
      // this.queryDelivery();
    },
    btnForMobile(filter) {
      this.deliveryStatus = filter;
      this.btnBackMobile = "hide-on-desktop";
      this.conditionView = "ml-md-auto show-on-desktop";
      this.btnshow = false;
    },
    actBackMobile() {
      this.btnBackMobile = "hide-at-all";
      this.conditionView = "ml-md-auto hide-on-mobile";
      this.btnshow = true;
    },
    //END METHOD FOR MOBILE VIEW
    toResend(row) {
      //ini buat kirim ulang ke SAP
      //resend to SAP

      //untuk memunculkan loading
      this.isLoading = true
      let wantedStatus = [
        "Resend Status",
        "Redelivery Status",
        "Status Completed",
        "Resend Return",
        "Return Completed",
      ];
      var data = row;

      this.testResendToSAP({ idDelivery: data._id, deliveryData: data })
        .then(() => {
          this.$bvToast.toast("Resend to SAP Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.getDeliveryOrder({
            page: this.currentPage,
            entry: this.perPage,
            status: this.deliveryStatus == "All" ? wantedStatus : this.deliveryStatus,
          }).then((data) => {
            console.log("this.delvieryStatus", this.deliveryStatus);
            this.processMetadata(data.metadata);
            this.isLoading = false
          });
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
            this.isLoading = false
            return;
          } else {
            this.$bvToast.toast(
              "Resend to SAP with " + data.sapDo + " failed",
              {
                title: "Failed",
                variant: "danger",
                solid: true,
              }
            );
          }
          this.isLoading = false
          console.log(e);
        });
    },
    displayFilter(data) {
      var status;
      if (
        data.resendAPI === "Resend Status" ||
        data.resendAPI === "Redelivery Status" ||
        data.resendAPI === "Status Completed"
      ) {
        status = "Update DO Status";
      }
      if (
        data.resendAPI === "Resend Return" ||
        data.resendAPI === "Return Completed"
      ) {
        status = "Return";
      }
      return status;
    },
    displayStatus(data) {
      var status;
      //jadi ini kalo ada yang gagal kirim ke SAP (update status DO dan Retur)
      if (
        data.resendAPI === "Resend Status" ||
        data.resendAPI === "Redelivery Status" ||
        data.resendAPI === "Resend Return"
      ) {
        status = "Resend";
      }
      //jadi ini kalo ada yang berhasil kirim ulang ke SAP (update status DO dan Retur)
      if (
        data.resendAPI === "Status Completed" ||
        data.resendAPI === "Return Completed"
      ) {
        status = "Completed";
      }
      return status;
    },
    toDetailPage(data) {
      let idDO = data._id;
      this.$router.push({
        name: "warehouse-detail-delivery",
        params: { id: idDO, from: "resend",status:this.deliveryStatus },
      });
    },
  },
};
</script>
<style scope>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}

.custom-row-height {
  min-height: 120px; /* Adjust this value as needed */
}
</style>
